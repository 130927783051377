import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';


import ReactSpeedometer from 'react-d3-speedometer';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const MapComponent = () => {
  


  const [position, setPosition] = useState({ lat: 33.5784223, lng: -7.5838085 });
  const [trajectory, setTrajectory] = useState([]);
  const [vehicleData, setVehicleData] = useState(null);
  const [vehicles, setVehicles] = useState([]); // State for storing list of vehicles
  const [selectedVehicule, setSelectedVehicule] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [vehicleDataCollection, setVehicleDataCollection] = useState([]);
  const [isAllowed,setIsAllowed]= useState(false);

  const fetchVehicles = () => {
    axios.get(process.env.REACT_APP_PEGASUS_API_URL + '/vehicules',
      {
      headers:{
        "ENCRYKEY":"d721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c"
      }
    }
    ) // Adjust this URL to where your vehicles are fetched from
      .then(response => setVehicles(response.data))
      .catch(error => console.error('Error fetching vehicles:', error));


  };

  const fetchVehicleData = () => {
    if (!selectedVehicule && !selectedVehicule.data_id)
      return;

    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_DATA_PIC_PATH}/tracker_vehicule.php?key=fadktygkcs5oa34bvpnnncpz&dataid=${selectedVehicule.data_id}&vehiculeid=${selectedVehicule.vehiculeId}`,
      headers: { 'accept': 'application/json' }
    };
    if (selectedVehicule && selectedVehicule.data_id)
      axios.request(config)
        .then(response => {
          if (response.data && response.data.length > 0) {
            const { latitude, longitude } = response.data[0];
            if (latitude !== undefined && longitude !== undefined) {
              const newPosition = { lat: latitude, lng: longitude };
              setPosition(newPosition);
              setTrajectory(prevTrajectory => [...prevTrajectory, newPosition]); // Ajoute la nouvelle position à l'historique
              setVehicleData(response.data[0]);
            }
          }
        })
        .catch(error => console.error('Error fetching vehicle data:', error));

  };

  const fetchAllVehiculeData = () => {
    if (selectedVehicule)
      axios.get(process.env.REACT_APP_PEGASUS_API_URL + '/vehiculeData',{
    headers:{
      "ENCRYKEY":"d721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c"
    }
      }) // Adjust this URL to where your vehicles are fetched from
        .then(response => setVehicleDataCollection(response.data))
        .catch(error => console.error('Error fetching vehicles:', error));

  };

  useEffect(() => {
    fetchVehicles(); // Fetch vehicles when component mounts
    
    let intervalId;
    if (selectedVehicule) {
      fetchVehicleData();
      // Fetch vehicle data based on the currently selected vehicle
      intervalId = setInterval(fetchVehicleData, 20000); // Set up the interval
    }else{
    
    }

    // Cleanup function to clear the interval when the component unmounts or selectedVehicule changes
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [selectedVehicule]);

  return (

    (true)?(
    <div className="container">
      <h1>Trajectoire et suivi</h1>
      <div className="row">
        <div className="col-md-12">
          <select className="form-control mb-3" onChange={e => setSelectedVehicule(vehicles.find(x => x.vehiculeId == e.target.value))}>
            <option value="">Select a vehicle</option>
            {vehicles.map(vehicle => (
              <option key={vehicle.vehiculeId} value={vehicle.vehiculeId}>
                {vehicle.matricule}
              </option>
            ))}
          </select>
          {vehicleData && (
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th>Matricule</th>
                  <th>Vitesse</th>
                  <th>Date</th>
                  <th>Heure</th>
                  <th>Contact</th>
                  <th>Historique</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{vehicleData.matricule}</td>
                  <td>   <ReactSpeedometer
                    value={vehicleData.speed} // La valeur de la vitesse que vous souhaitez afficher
                    minValue={0} // Valeur minimale
                    maxValue={180} // Valeur maximale
                    needleColor="white" // Couleur de l'aiguille
                    startColor="green" // Couleur de départ de l'arc
                    segments={10} // Nombre de segments
                    endColor="red" // Couleur de fin de l'arc
                    currentValueText="Vitesse actuelle : ${value} km/h" // Texte affiché
                  /></td>
                  <td>{vehicleData.date}</td>
                  <td>{vehicleData.time}</td>
                  <td>
                    <span className={`badge ${vehicleData.contact ? 'bg-success' : 'bg-danger'}`}>
                      {vehicleData.contact ? 'En Marche' : 'Arrêté'}
                    </span>
                  </td>
                  <td>
                    <button className='btn btn-success px-5' onClick={() => { fetchAllVehiculeData(); setShowModal(true); }}>
                      Ouvrir
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>

        <div className="col-md-12">
          <MapContainer center={position} zoom={13} style={{ height: '70vh', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}></Marker>
            <Polyline positions={trajectory} color="blue" />
          </MapContainer>

        </div>
      </div>
      {showModal && (
        <div className="modal modal-xl fade show d-block" id="yourModal" tabIndex="-1" role="dialog" aria-labelledby="yourModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="yourModalLabel">Historique des localisations</h5>
                <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Vehicule ID</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Speed</th>
                      <th>Contact</th>
                      <th>Added Date</th>
                      <th>Added From</th>
                      <th>Voir</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleDataCollection.map((data, index) => (
                      <tr key={index}>
                        <td>{data.vehiculeId}</td>
                        <td>{data.date}</td>
                        <td>{data.time}</td>
                        <td>{data.latitude}</td>
                        <td>{data.longitude}</td>
                        <td>{data.speed}</td>
                        <td>{data.contact}</td>
                        <td>{data.add_date}</td>
                        <td>{data.add_from}</td>
                        <td><button onClick={() => {
                          const newPosition = { lat: data.latitude, lng: data.longitude };
                          setPosition(newPosition);
                          setTrajectory([...trajectory, newPosition]);
                          setShowModal(false);
                        }} className='btn btn-warning'>Voir</button></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>):(<></>)


  );
};

export default MapComponent;
