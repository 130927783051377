import React, { useEffect } from 'react';

import axios from 'axios';
import { useState } from 'react';
import Select from 'react-select';

function ListofProducts(props) {
    const [categoriesOptions,setCategoriesOption] =useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const fetchCategory = ()=>{
   

        // Define the headers
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_API_CLIENT_WEBSITE}:${process.env.REACT_APP_API_SECRET_WEBSITE}`)
        };
        
        // Use a proxy to handle CORS issues (for development/testing purposes only)
        
        const targetUrl = `${process.env.REACT_APP_WEBSITE_API}/wp-json/wc/v3/products/categories?per_page=100`;
        
        axios.get( targetUrl, { headers })
          .then(response => {
            // Handle the response data
            const categories = response.data.map(x => ({ value: x.id, label: x.name }));
            setCategoriesOption(categories);
          })
          .catch(error => {
            // Handle the error
            console.error('Error:', error);
          });
        }
        useEffect(()=>{
            fetchCategory();
        },[]);


        const onChangeCategory = (newValue) =>{
                setSelectedCategories(newValue);
        };

    return (
        <div className="container mt-0">
            
            <div>
            <label htmlFor="categories" className="form-label">Catégories</label>
        <Select
          id="categories"
          
          options={categoriesOptions}
          value={selectedCategories}
          onChange={onChangeCategory}
        />
            </div>
        </div>
    );
}

export default ListofProducts;