
import Login from './pages/Login';
import DashBoard from './pages/DashBoard';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useAuth from './components/useAuth';
import CmiAccounting from './pages/CmiAccounting';
import AddProductWebsite from './pages/AddProductWebsite';
import ListofProducts from './pages/ListofProducts';
import PrivateRoute from './components/PrivateRoute';
import OrdersGlovo from './pages/OrdersGlovo';
import MapComponent from './pages/MapComponent';
import dotenv from 'react-dotenv';

// Charger les variables d'environnement


function App() {
  return (
    <>
    
      <Routes>
      <Route  path="cmiExcel" element={ <PrivateRoute ><CmiAccounting /></PrivateRoute>} />
      <Route path='/addproductsite' element={<PrivateRoute ><AddProductWebsite /></PrivateRoute>} />
      <Route path='/productList' element={<PrivateRoute ><ListofProducts /></PrivateRoute>} />
      <Route path="/dashboard" element={<PrivateRoute ><DashBoard /></PrivateRoute>} />
      <Route path='/ordersGlovo' element={<PrivateRoute ><OrdersGlovo /></PrivateRoute>}/>
      <Route path='/tracker' element={<PrivateRoute ><MapComponent /></PrivateRoute>}/>
      <Route path='/login' element={<Login />} />
    
      <Route index element={<PrivateRoute ><DashBoard /></PrivateRoute>} />
    </Routes>

    </>
  );
}

export default App;
